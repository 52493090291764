<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="entityDialog"
      :max-width="dialogWidth"
      content-class="entity-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">Select an Entity</h4>
          <v-spacer></v-spacer>
          <v-btn icon dark color="cyan" v-on:click="$emit('resetAll', true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which entity would you like to create this for?
              </h5>
              <table v-if="false" width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-entity"
                      v-model.trim="entity"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <template v-if="false && getPermission('entity:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Entity
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="entityList.length > 0">
                        <v-list-item
                          :disabled="!data.customers_count"
                          v-on:click="selectEntity(data)"
                          v-for="(data, index) in entityList"
                          :key="index"
                          link
                        >
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="
                                $assetURL('media/custom-svg/entity.svg')
                              "
                              :src="$assetURL('media/custom-svg/entity.svg')"
                              aspect-ratio="1"
                              contain
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              <template v-if="data.address">{{
                                data.address
                              }}</template>
                              <template v-else><em>No Address</em></template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="margin-auto">
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                              >{{ data.customers_count }}
                              Customer(s)
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Entity(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import { GET } from "@/core/services/store/request.module";
// import { getConfig } from "@/core/services/local.service";

export default {
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: false,
      entity: null,
      timeoutLimit: 500,
      timeout: null,
      entityList: new Array(),
    };
  },
  props: {
    entityDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
  },
  watch: {
    entity(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchEntity();
        }, _this.timeoutLimit);
      }
    },
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchEntity();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchEntity();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.entity = null;
        _this.isFocused = false;
      });
    },
    searchEntity() {
      const _this = this;
      _this.searchLoader = true;
      _this.$store
        .dispatch(GET, { url: "setting/entity" })
        .then(({ data }) => {
          _this.entityList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
        });
    },
    selectEntity(param) {
      this.$emit("selectEntity", param.id);
    },
  },
  mounted() {
    // this.entityList = getConfig('entity');
    this.searchEntity();
    // this.$refs["search-entity"].focus();
  },
  computed: {
    isSearching() {
      return this.entity && this.entity.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Entity...";
    },
  },
};
</script>
